import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

import { useBins } from 'lib/custom_hooks';
import { WASTE_REASONS, WASTED, WASTE, DEPLETED } from 'lib/constants';
import { StatefulButton, SnackbarSimpleError } from 'components/shared';
import { PrinterContext } from 'components/RthProductionSubmissions/PrinterProvider';

import BinFormDrawer from '../BinFormDrawer';
import styles from '../styles';

const useStyles = makeStyles(styles);

export const WasteForm = ({ bin }) => {
  const classes = useStyles();
  const [open, toggleOpen] = useState(false);
  const [weightInLbs, setWeightInLbs] = useState(null);
  const [wasteReason, setWasteReason] = useState(WASTE_REASONS[0]);
  const [trayQuantity, setTrayQuantity] = useState('');

  const { setPrinterDialogOpen, setPrintSubmission, setBinMode } = useContext(
    PrinterContext
  );

  const buttonText = {
    DEFAULT: 'Send To Waste',
    SUCCESS: 'Success!',
    LOADING: 'Saving...',
    ERROR: 'Error - Try Again',
  };

  const {
    onUpdateBin,
    submitting,
    submittingError,
    resetBinsErrors,
  } = useBins();

  const toggleModal = () => {
    resetBinsErrors();
    setWasteReason(WASTE_REASONS[0]);
    setWeightInLbs('');
    setTrayQuantity('');
    toggleOpen(!open);
  };

  const onWasteBin = async () => {
    const params = {
      weightInLbs,
      trayQuantity,
      statusReason: WASTED,
      reasonCode: wasteReason,
    };

    const response = await onUpdateBin({ binId: bin.id, binParams: params });

    if (response.ok) {
      const binAssignment = response.bin.binAssignments.findLast(
        (ba) => ba.action === WASTE
      );

      setPrintSubmission({
        binAssignmentIds: [binAssignment.id],
      });
      setBinMode(true);
      setPrinterDialogOpen(true);
    }
  };

  const negativeTrayQuantity = trayQuantity && trayQuantity <= 0;
  const invalidTrayQuantity = !trayQuantity || negativeTrayQuantity;

  const submitDisabled =
    isEmpty(wasteReason) ||
    isEmpty(weightInLbs) ||
    (bin.isSauce && invalidTrayQuantity);

  return (
    <>
      <Button
        className={classes.wasteButton}
        variant="outlined"
        onClick={toggleModal}
        disabled={[WASTED, DEPLETED].includes(bin.statusReason)}
      >
        Waste
      </Button>
      <BinFormDrawer
        open={open}
        toggleOpen={toggleOpen}
        data-testid={`waste-form-bin-${bin.id}`}
      >
        <div className={classes.formContainer}>
          <div className={classes.entryContainer}>
            <Typography className={classes.formHeader}>
              Send to Waste
            </Typography>
            <Typography className={classes.formSubheader}>
              Bin {bin.id} - {bin.ingredientName}
            </Typography>
            <div className={classes.questions}>
              <div className={classes.questionContainer}>
                <div className={classes.questionText}>
                  What is the weight in pounds (lbs)?
                </div>
                <div className={classes.textFieldWrapper}>
                  <TextField
                    label="Weight in Lbs"
                    type="number"
                    variant="outlined"
                    value={weightInLbs}
                    onChange={(e) => setWeightInLbs(e.target.value)}
                  />
                </div>
              </div>
              {bin.isSauce && (
                <div className={classes.questionContainer}>
                  <div className={classes.questionText}>
                    How many trays are being reallocated?
                  </div>
                  <div className={classes.textFieldWrapper}>
                    <TextField
                      error={negativeTrayQuantity}
                      helperText={
                        negativeTrayQuantity &&
                        'Tray quantity must be greater than 0'
                      }
                      label="Tray Quantity"
                      onChange={(e) => setTrayQuantity(e.target.value)}
                      type="number"
                      value={trayQuantity}
                      variant="outlined"
                    />
                  </div>
                </div>
              )}
              <div className={classes.questionContainer}>
                <div className={classes.questionText}>
                  What is the reason this product is being sent to waste?
                </div>
                <div className={classes.textFieldWrapper}>
                  <TextField
                    select
                    label="Waste Reason"
                    data-testid="waste-reason-selector"
                    variant="outlined"
                    value={wasteReason}
                    onChange={(e) => setWasteReason(e.target.value)}
                  >
                    {WASTE_REASONS.map((reason) => (
                      <MenuItem key={reason} value={reason}>
                        {startCase(reason)}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.drawerButtonContainer}>
            <div className={classes.drawerSubmitButtonWrapper}>
              <StatefulButton
                buttonTextOptions={buttonText}
                classes={{ root: classes.drawerSubmitButton }}
                disabled={submitDisabled}
                failed={!!submittingError}
                loading={submitting}
                onClick={onWasteBin}
                successCallback={toggleModal}
                type="submit"
                variant="contained"
              >
                Confirm
              </StatefulButton>
            </div>
            <SnackbarSimpleError
              open={!!submittingError}
              message={submittingError}
            />
          </div>
        </div>
      </BinFormDrawer>
    </>
  );
};

WasteForm.propTypes = {
  bin: PropTypes.object.isRequired,
};

export default WasteForm;
