import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Chip,
  Collapse,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import { EXPIRED, DEACTIVATED } from 'lib/constants';

import BinActions from './BinActions';
import BinDetails from './BinDetails';
import styles from './styles';

const useStyles = makeStyles(styles);

export const BinRow = ({ bin, expandAll, trigger }) => {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    setExpand(expandAll);
  }, [expandAll, trigger]);

  const renderAssignedMeals = () => {
    const allMealsString = bin.assignedMeals
      .map((meal) => `${meal.mealLetterAssignment} ${meal.mealName}`)
      .join(' → ');

    return (
      <div className={classes.titleCardDetailItem}>
        <div className={classes.subTitle}>Assigned Meal:</div>
        <div className={classes.subInfo}>{allMealsString}</div>
      </div>
    );
  };

  const renderExpiration = () => {
    return (
      <div className={classes.titleCardDetailItem}>
        <div className={classes.subTitle}>
          {bin.statusReason == EXPIRED ? 'Expired At' : 'Expires At'}:
        </div>{' '}
        <div className={classes.subInfo}>{bin.expiresAt}</div>
      </div>
    );
  };

  const renderStatusReason = () => {
    if (bin.status === DEACTIVATED) {
      return (
        <Chip
          className={classes.chip}
          label={bin.statusReason.toUpperCase()}
          size="small"
        />
      );
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography className={classes.title}>
            {`Bin ${bin.id} - ${bin.ingredientName}`}
            {renderStatusReason()}
          </Typography>
          <div className={classes.titleCardDetailsSection}>
            {renderAssignedMeals()}
            {renderExpiration()}
          </div>
          <Button
            className={classes.detailsButton}
            role="button"
            onClick={() => setExpand(!expand)}
          >
            {expand ? 'Hide Details' : 'View Details'}
          </Button>
        </TableCell>
        <TableCell className={classes.actionCell}>
          <BinActions bin={bin} />
        </TableCell>
      </TableRow>
      <TableRow
        className={expand ? classes.detailsRow : classes.detailsRowHidden}
      >
        <TableCell colSpan={2}>
          <Collapse data-testid="bin-row-details-collapse" in={expand}>
            <BinDetails bin={bin} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

BinRow.propTypes = {
  bin: PropTypes.object.isRequired,
};
