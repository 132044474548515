import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Select,
  MenuItem,
  Switch,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';

import { ACTIVE, EXPIRED } from 'lib/constants';
import ExpandAndCollapsibleButtons from 'components/RthProgressTracker/ExpandAndCollapsibleButtons';
import { Loading, StatusCard, MenuNavigation } from 'components/shared';
import useBins from 'lib/custom_hooks/useBins';

import styles from './styles';
import BinFilter from './BinFilter';
import BinRow from './BinRow';

const useStyles = makeStyles(styles);

// SORTING CONSTANTS
// key names on bin object that we'll sort off of
const EXPIRES_AT = 'expiresAt';
const INGREDIENT_NAME = 'ingredientName';
const MEAL_NAME = 'mealName';

// mapping of key names to select display names
const sortOptions = [
  { value: EXPIRES_AT, displayName: 'FEFO' },
  { value: INGREDIENT_NAME, displayName: 'Ingredient Name' },
  { value: MEAL_NAME, displayName: 'Meal Name' },
];

export const Binventory = () => {
  const classes = useStyles();

  const { bins, onFetchBins, fetching, fetchingError } = useBins();
  const { menuId } = useParams();

  const [expandAll, setExpandAll] = useState(false);
  const [filterInput, setFilterInput] = useState('');
  const [selectedBins, setSelectedBins] = useState([]);
  const [showDeactivatedBins, setShowDeactivatedBins] = useState(false);
  const [sortBy, setSortBy] = useState(EXPIRES_AT);
  const [sortedBins, setSortedBins] = useState([]);
  const [trigger, setTrigger] = useState(false);

  // show expired ones on top, otherwise show active bins on top;
  // then sort by user selected value (if different from EXPIRES_AT),
  // then by EXPIRES_AT (this way we get FEFO for other sort selections),
  // and lastly by id (for consistent ordering every time)
  const sortBins = (parameter) => {
    return [...selectedBins].sort(
      (a, b) =>
        (showDeactivatedBins
          ? (b.statusReason === EXPIRED) - (a.statusReason === EXPIRED)
          : (b.status === ACTIVE) - (a.status === ACTIVE)) ||
        (parameter !== EXPIRES_AT &&
          a[parameter]?.localeCompare(b[parameter])) ||
        new Date(a.expiresAt) - new Date(b.expiresAt) ||
        a.id - b.id
    );
  };

  useEffect(() => {
    menuId && onFetchBins({ showDeactivatedBins, menuId });
  }, [showDeactivatedBins, menuId]);

  useEffect(() => {
    if (filterInput?.length > 0) {
      const selected = bins.filter((bin) => {
        return (
          bin.id.toString().startsWith(filterInput) ||
          bin.ingredientName.toLowerCase().includes(filterInput.toLowerCase())
        );
      });

      setSelectedBins(selected);
    } else {
      setSelectedBins(bins);
    }
  }, [bins, filterInput]);

  useEffect(() => {
    if (sortBy) {
      const sorted = sortBins(sortBy);
      setSortedBins(sorted);
    }
  }, [sortBy, selectedBins]);

  const renderControlsBar = () => (
    <div className={classes.controlsBar}>
      <div>
        <div className={classes.selectPreText}>SORT BY:</div>
        <Select
          className={classes.selectText}
          data-testid="sort-select"
          onChange={(e) => setSortBy(e.target.value)}
          size="small"
          value={sortBy}
          variant="standard"
        >
          {sortOptions.map(({ value, displayName }) => (
            <MenuItem className={classes.selectText} key={value} value={value}>
              {displayName}
            </MenuItem>
          ))}
        </Select>
        <div className={classes.switch}>
          <Switch
            checked={showDeactivatedBins}
            onChange={(e) => setShowDeactivatedBins(e.target.checked)}
          />
          Show Deactivated Bins
        </div>
      </div>
      <ExpandAndCollapsibleButtons
        setExpandAll={setExpandAll}
        trigger={trigger}
        setTrigger={setTrigger}
      />
    </div>
  );

  const renderTableContents = () => {
    if (fetchingError) {
      return (
        <TableRow>
          <TableCell>
            <StatusCard
              width="320px"
              height="76px"
              announcement="Error"
              details="Bins failed to load"
              error
            />
          </TableCell>
        </TableRow>
      );
    } else if (isEmpty(bins)) {
      return (
        <TableRow>
          <TableCell>
            <StatusCard
              width="320px"
              height="76px"
              announcement="No bins available"
              details="There are no bins to display"
            />
          </TableCell>
        </TableRow>
      );
    } else {
      return sortedBins.map((bin) => (
        <BinRow
          key={bin.id}
          bin={bin}
          expandAll={expandAll}
          trigger={trigger}
        />
      ));
    }
  };

  const renderTable = () => {
    return (
      <TableContainer className={classes.container} component={Paper}>
        <Table>
          <TableBody>{renderTableContents()}</TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderPage = () => {
    return (
      <>
        <MenuNavigation menusCount={3} />
        {renderControlsBar()}
        <BinFilter setFilter={setFilterInput} />
        {fetching ? <Loading fullScreen /> : renderTable()}
      </>
    );
  };

  return <div className={classes.wrapper}>{renderPage()}</div>;
};
